import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { getRequest } from '../../services'
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../state_store/snackbarcontroller';


interface CartState {
    data: {
        order_details: any
    }
}

const initialState: CartState = {
    data: {
        order_details: {}
    }
}

const CartSlice = createSlice({
    name: 'Cart',
    initialState,
    reducers: {
        setOrderDetails(state, action: PayloadAction<any>) {
            state.data.order_details = action.payload  // Update state with action payload
        }
    }
})

// Export actions
export const { setOrderDetails } = CartSlice.actions
// Export reducer
export default CartSlice.reducer

export const fetchOrderDetails = () => async (dispatch: AppDispatch) => {
    try {
        // Example: fetching data from an API
        const response: any = await getRequest(`orders/cart `)
        if (response.status === 200 || response.status === 201) {
            dispatch(setOrderDetails(response.data.data))
          
        } else {
            dispatch(setOrderDetails({}))
        }
    } catch (error: any) {
        dispatch(setOrderDetails({}))
       
        console.error('Error fetching homepage data', error)
    }
}
export const removeOrderDetails = (ID: any) => async (dispatch: AppDispatch) => {
    try {
        // Example: fetching data from an API

        const response: any = await getRequest(`orders/cart/remove?id=${ID}`)
        if (response.status === 200 || response.status === 201) {
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } 
    } catch (error: any) {
        dispatch(setSnackbar({ open: true, message: error?.response.data.message, severity: "error" }));
        console.error('Error fetching homepage data', error)
    }
}

export const useAppDispatch: () => AppDispatch = useDispatch;