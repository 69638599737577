import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import "./button.scss"


interface AppProps {
    lable: string;
    type: 'text' | 'outlined' | 'contained';
    clickFunction?: () => void; // Optional prop
}

const CustomButton: React.FC<AppProps> = ({ lable, type, clickFunction = () => console.log("onClick"), }) => {
    return (
        <div className={type === "contained" ? "Button-container-contained" : "Button-container"}>
            <Button variant={type} className='button' onClick={clickFunction}>{lable}</Button>

        </div>
    );
}

export default CustomButton;