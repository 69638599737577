import './App.scss';
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home/index";
import CategoryPage from './pages/Category';
import { fetchHomePageData, useAppDispatch } from "./pages/Home/controller";
import { useEffect, useState } from 'react';
import AddToCart from './pages/ProductDetails';
import Login from './pages/Onboarding';
import ShoppingCart from './pages/Cart';
function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchHomePageData());
  }, [])

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/products/:category_id/*" element={<CategoryPage />} />
        <Route path="/order_details/:product_id" element={<AddToCart />} />
        <Route path="/cart" element={<ShoppingCart />} />
      </Routes>
    </div>
  );
}

export default App;
