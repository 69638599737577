import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import "./selectInput.scss";
import { useState } from 'react';

interface CustomeSelectProps {
    Content: any; // Content is an array of strings
    label: any; // Label is a string
    height?: any; //
    width?: any;
    OnchangeFunction?: (label: string, value: string) => void;// Onchange is an optional function with no parameters and no return value
}
export default function CustomeSelect({ Content, label, height, width, OnchangeFunction }: CustomeSelectProps) {
    const [select, setSelect] = useState<any>(Content[0]?.value)
    const handleChange = (event: SelectChangeEvent) => {
        setSelect(event.target.value)
        if (OnchangeFunction) {
            OnchangeFunction(label, event.target.value); // Call function with label and value
        }
    };
    React.useEffect(() => {
        // Get the current URL's query parameters
        const searchParams = new URLSearchParams(window.location.search);
        const quantity = searchParams.get(label); // Check for 'Quantity' in the URL

        if (quantity) {
            setSelect(quantity); // Set the state if 'Quantity' exists
        }
    }, [label]);

    return (
        <Box sx={{ minWidth: 120, width: width ? width : '100%', }}>
            <FormControl fullWidth size="small" className="custom-select">
                {/* {!label && <InputLabel id="demo-simple-select-label">"Sort"</InputLabel>} */}
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={select}
                    className={`${height ? `h-[${height}px]` : ""} ${width ? `w-[${width}px]` : ""}`}
                    onChange={handleChange}
                >
                    {Content?.map((item: any) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}

                </Select>
            </FormControl>
        </Box >
    );
}