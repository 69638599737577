export const calculatePercentageDifference = (basePrice: number, maxPrice: number) => {
    // Check if the basePrice is valid
    if (basePrice <= 0) {
        throw new Error("Base price must be greater than 0.");
    }

    // Calculate the percentage difference
    const difference = maxPrice - basePrice;
    const percentage = (difference / basePrice) * 100;

    return percentage;
}
export const validateRegisterForm = (form: any, setForm: any): boolean => {
    const updatedForm = { ...form };
    let hasError = false;

    Object.keys(updatedForm).forEach((key) => {
        const field = updatedForm[key];

        if (key === "name" || key ==="phone") {
            if (Array.isArray(updatedForm[key])) {
                updatedForm[key] = updatedForm[key].map((field: any) => {
                    if (field.required) {
                        if (!field.value || field.value.trim() === "") {
                            return {
                                ...field,
                                error: true,
                                message: `${field.label} is required.`
                            };
                        } else {
                            return {
                                ...field,
                                error: false,
                                message: ""
                            };
                        }
                    }
                    return field; // Return unchanged field if not required
                });
            }
        }
        

        if (field.required) {
            if (!field.value || field.value.trim() === "") {
                field.error = true;
                field.message = `${field.label} is required.`;
                hasError = true;
            } else {
                field.error = false;
                field.message = "";
            }
        }
    });

    setForm(updatedForm);

    // Return false if there are errors, true otherwise
    return !hasError;
};
