import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
            className='flex justify-center items-start'
        >
            {value === index && (
                Array.isArray(children) ? (
                    <Box
                        sx={{ p: 3 }}
                        className="w-[70%] p-[20px] "
                    >
                        {children.map((item, idx) => (

                            <p key={idx} className='text-secondary_text text-[13px] pb-[15px]'>
                                {item}
                            </p>
                        ))}
                    </Box>
                ) : (
                    <Box
                        sx={{ p: 3 }}
                        className="w-[70%] p-[20px]"
                    >
                        <p className='text-secondary_text text-[13px]'>
                            {children}
                        </p>

                    </Box>
                )
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
interface Props {
    tab_label: any; // Replace `any` with the actual type if known (e.g., string, JSX.Element, etc.)
    tab_value: any; // Replace `any` with the actual type if known (e.g., number, string, etc.)
}

export default function BasicTabs({ tab_label, tab_value }: Props) {
    const [value_no, setValue_no] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue_no(newValue);
    };

    // console.log(tab_value);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
                <Tabs style={{
                     display: 'flex',
                     justifyContent: 'center',
                    fontSize: 'calc(6px + 2vmin)'
                }} value={value_no} onChange={handleChange} variant="scrollable"
                    scrollButtons
                    allowScrollButtonsMobile
                    centered
                    aria-label="scrollable force tabs example">
                    {tab_label.map((label: any, index: any) => (
                        <Tab label={label} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {tab_value.map((value: any, index: any) => (
                <CustomTabPanel value={value_no} index={index}>
                   <div dangerouslySetInnerHTML={{ __html: value.content }}></div>
                </CustomTabPanel>
            ))}
        </Box>
    );
}
