import { configureStore } from '@reduxjs/toolkit'
import HomePageReducer from '../pages/Home/controller'
import CategoryReducer from "../pages/Category/controller"
import LoginPageReducer from "../pages/Onboarding/controller"
import SnackbarReducer from "./snackbarcontroller/index"
import ProductPageReducer from "../pages/ProductDetails/controller"
import CartReducer from "../pages/Cart/controller"



const store = configureStore({
  reducer: {
    homePage: HomePageReducer,
    categoryPage: CategoryReducer,
    loginPage: LoginPageReducer,
    snackbar: SnackbarReducer,
    productPage: ProductPageReducer,
    cartPage: CartReducer,

  },
  // Redux Toolkit automatically includes redux-thunk, so no need to add it separately
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
