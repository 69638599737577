import React, {useState} from "react";
import { Box, TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./customeTextFeild.scss"


interface InputWithProps {
    message: string;
    value: string;
    onChangeFunction: any;
    label: any;
    required: any;
    error: any;
    placeholder: string;
    type: string;
}




const TextFieldCustom: React.FC<InputWithProps> = ({
    message = "",
    value = "",
    label,
    onChangeFunction,
    required,
    error,
    placeholder,
    type,
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword((prev) => !prev);
    };

    const isPasswordField = label === "Password" || label === "Confirm Password";

    return (
        <Box className="SelectInput">
            {label && <h3>{label}</h3>}
            <TextField
                required={required}
                fullWidth
                error={error}
                id="outlined-required"
                value={value}
                type={isPasswordField && !showPassword ? "password" : type}
                helperText={message}
                placeholder={placeholder}
                onChange={onChangeFunction ? onChangeFunction : undefined}
                className="p-[14px]"
                size="small"
                disabled={label === "Country" || label === "PhoneNumberVerify"}
                sx={{
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                        borderRadius: "5px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: "5px", // Apply borderRadius to the outline
                    },
                }}
                InputProps={{
                    endAdornment: isPasswordField ? (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleTogglePasswordVisibility}
                                edge="end"
                                aria-label="toggle password visibility"
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ) : null,
                }}
            />
        </Box>
    );
};

export default TextFieldCustom;
