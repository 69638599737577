import Layout from "../../component/Layout/index"
import Description from "../../component/Text/Description";
import Title from "../../component/Text/Title";
import "./category.scss"
import ProductCart from "../../component/Cart/ProductCart";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import RadioButtonsGroup from "../../component/Groupradio";
import CustomeSearchCheckbox from "../../component/Checkbox_with_search";
import SizeSelector from "../../component/Select_box_with_search";
import CustomeSelect from "../../component/Select_Input";
import { useLocation } from 'react-router-dom';
import { getRequest } from "../../services";
import { fetchProductsCategories } from "./controller";
import { useAppDispatch } from "./controller";



function CategoryPage() {
    const location = useLocation();
    const path = location.pathname;
    // Extract the ID after `/products/` and before `%20`
    const category_id = path.split("/products/")[1]?.split("%20")[0]
    const dispatch = useAppDispatch();
    const homePageData = useSelector((state: any) => state.homePage.data)
    const productList = useSelector((state: any) => state.categoryPage.data)
    const [categoryDetails, setCategoryDetails] = useState<any>(null);
    const [filter, setFilter] = useState<any>([])
    const [filterationObj, setFilterationobj] = useState<any>({
        category: [],  // Update categories_id
        price: [],
        page: 1,
        rpp: 10
    })


    useEffect(() => {
        if (category_id && homePageData[0]?.categories) {
            const Category = homePageData[0]?.categories.filter((item: any) => (item._id === category_id))
            setCategoryDetails(Category[0])
            getFilterData(category_id)

        }
    }, [category_id, homePageData[0]?.categories]);

    const defaultProductList = async ({ category, price }: { category?: any; price?: any }) => {
        setFilterationobj((prevState: any) => {
            // Build categories_id by combining category_id and the provided category if any
            const categories_id = category ? [category_id, ...category] : [category_id];

            // Return the updated state
            return {
                category: categories_id,                  // Update categories_id
                price: price ?? prevState.price, // Retain previous price if not provided
                page: 1,                        // Reset pagination
                rpp: 10                         // Results per page
            };
        });

        // Wait for state update before dispatching (optional, for consistency)
        await new Promise((resolve) => setTimeout(resolve, 0));
        // console.log(filterationObj)
        // // Dispatch the fetch action with the updated filteration object
        // dispatch(fetchProductsCategories(filterationObj));
    };


    useEffect(() => {
        dispatch(fetchProductsCategories(filterationObj));
    }, [filterationObj])



    // useEffect(() => {
    //     if (categoryDetails) {
    //         dispatch(fetchProductsCategories([categoryDetails?._id], 1, 10));
    //     }

    // }, [categoryDetails])

    const getFilterData = async (product_id: any) => {
        try {
            const url = `store-category?$filter=_id%20eq%20${product_id}`
            const filterData = await getRequest(url)
            setFilter(filterData?.data?.data[0]?.filters)

        } catch (error) {
            // console.log(error)
        }
    }

    // console.log(productList)



    const sortingOptions = [
        { value: "a-to-z", label: "A to Z" },
        { value: "price-high-to-low", label: "Price-High to Low" },
        { value: "price-low-to-high", label: "Price-Low to High" },
        { value: "newest", label: "Newest" },
        { value: "popularity", label: "Popularity" }
    ];


    return (
        <Layout>
            <div className="Category-Page">
                <img src={homePageData[0]?.store_design?.carosel?.images[0]?.banner} alt="category-banner" className="Category-banner" />
                <div className="Category-Page-Content">
                    <div className="Category-section">

                        <div className="Category-section-with-sort">
                            <div className="Category-Name-and-filter">
                                <div className="Category-section-information">
                                    <Title>Home / {categoryDetails?.category_name}</Title>
                                    <Description> DC Comics Official Merchandise - 236 items</Description>
                                </div>
                                <div className="Categroy-Filter" >
                                    <CustomeSelect label={false} Content={sortingOptions} />
                                </div></div>

                        </div>

                        <div className="Category-flex-component">
                            <div className="Category-section-filter">
                                {filter.length > 0 && filter?.map((item: any) => {

                                    if (item?.type === "Multi Select") {
                                        return <CustomeSearchCheckbox categories={item} productList={defaultProductList} category_id={categoryDetails?._id} />;
                                    }
                                    if (item?.type === "Single Select") {
                                        return <RadioButtonsGroup content={item} productList={defaultProductList} />;
                                    }
                                    if (item?.type === "Chip Select") {
                                        return <SizeSelector categories={item} />;
                                    }
                                    return null; // In case none of the conditions match
                                })}
                            </div>

                            <div className="Category-section-information">
                                <div className="Category-section-content">
                                    <ProductCart data={productList?.data ?? []} />
                                </div>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default CategoryPage;
