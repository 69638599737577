import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../state_store/snackbarcontroller';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});



const SelfManagedSnackbar: React.FC = () => {
    const SnackbarState = useSelector((state: any) => state.snackbar.data)
    const dispatch = useDispatch();
    // console.log(SnackbarState)
    const closeSnackbar = () => {
        dispatch(setSnackbar({ open: false, message: "", severity: "" }));
    };

    return (
        <div>
            <Snackbar
                open={SnackbarState.open}
                autoHideDuration={6000}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={closeSnackbar} severity={SnackbarState.severity}>
                    {SnackbarState.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SelfManagedSnackbar;
