import { useEffect, useState } from 'react';
// import './group_radio.scss'



export default function ProductImages({ ImageList }: any) {

    const [selectedImage, setSelectedImage] = useState<any>("");
    useEffect(() => {
        if (Array.isArray(ImageList) && ImageList.length > 0) {
            setSelectedImage(ImageList[0]?.banner)
        } else {
            setSelectedImage("")
        }

    }, [ImageList])

    return (
        <div className='w-full flex sm:flex-row flex-col-reverse items-start justify-between p-[40px] sm:gap-[0px] gap-[20px]'>
            <div className='sm:w-[12%] w-[15%] flex sm:flex-col flex-row items-start justify-between gap-[30px]'>
                {ImageList?.map((img: any, index: any) => (
                    <img
                        key={index}
                        src={img?.banner}
                        alt={`Thumbnail ${index}`}
                        onClick={() => setSelectedImage(img?.banner)}
                        className='w-full h-[60px] cursor-pointer object-cover mr-[10px] rounded-[10px]'

                    />
                ))}
            </div>
            <div className='sm:w-[78%] w-full'>
                <img
                    src={selectedImage}
                    alt="Selected"
                    className='w-full h-[400px] object-cover  rounded-[10px]'

                />
            </div>

        </div>
    );
}