import React, { useState } from 'react';
import { ChevronDown, ChevronUp, Tag, Gift, CreditCard } from 'lucide-react';
import CustomButton from "./../Button";
import TextFieldCustom from '../TextField';

const CouponBillingSection = () => {
    const [isCouponOpen, setIsCouponOpen] = useState(false);
    const [isGiftOpen, setIsGiftOpen] = useState(false);
    const [isTssMoney, setIsTssMoney] = useState(false);
    const [isGiftWrapChecked, setIsGiftWrapChecked] = useState(false);

    return (
        <div className="max-w-md mx-auto p-4 space-y-4">
            {/* Apply Coupon Section */}
            <div className="border rounded-lg">
                <button
                    onClick={() => setIsCouponOpen(!isCouponOpen)}
                    className="w-full p-2 flex items-center justify-between text-gray-700"
                >
                    <div className="flex items-center gap-2">
                        <Tag className="w-5 h-5" />
                        <span className="text-primary_text font-medium text-[12px]">Apply Coupon</span>
                    </div>
                    {isCouponOpen ? <ChevronUp className="w-5 h-5" /> : <ChevronDown className="w-5 h-5" />}
                </button>

                {isCouponOpen && (
                    <div className="p-2 pt-0 ">
                        <div className="flex gap-2 my-2 items-center">
                              <TextFieldCustom
                                placeholder={"Apply Code"}
                                type={"text"}
                                message={""}
                                value={""}
                                required={true}
                                error={false}
                                onChangeFunction={(e: any) => console.log(e)}
                                label={""}
                            />
                            <CustomButton lable={"Apply"} type="outlined" />
                        </div>
                    </div>
                )}
            </div>

            {/* Gift Wrap Section */}
            <div className="flex items-center justify-between p-2 border rounded-lg">
                <div className="flex items-center gap-2">
                    <Gift className="w-5 h-5 text-gray-700" />
                    <span className="font-medium text-[12px] text-gray-700">Gift Wrap (₹ 25)</span>
                </div>
                <input
                    type="checkbox"
                    checked={isGiftWrapChecked}
                    onChange={(e) => setIsGiftWrapChecked(e.target.checked)}
                    className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-0 focus:outline-none checked:bg-primary-600 checked:border-primary-600"
                />
            </div>

        


        </div>
    );
};

export default CouponBillingSection;