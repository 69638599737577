import Layout from "../../component/Layout/index"
import "./productdetails.scss"
import ProductImages from "../../component/Products/Product_images/index";
import ProductDetails from "../../component/Products/Products_details";
import BasicTabs from "../../component/Tab";
import { useSelector } from 'react-redux'
import ProductCart from "../../component/Cart/ProductCart";
import CustomButton from "../../component/Button";
import { fetchProductDetails, useAppDispatch } from "./controller";
import { useEffect } from "react";
import { useParams } from "react-router-dom";




function AddToCart() {
    const dispatch = useAppDispatch();
    const { product_id } = useParams();
    const productDetails = useSelector((state: any) => state.productPage.data?.page_details)

    const tab_label = ["Description", "Addiontional Information", "Review"];
    const tab_content = [
        {
            label: "Description",
            content: productDetails?.product_details?.description
        },
        {
            label: "Additional Information",
            content: `
                <p>You can add detailed technical specifications here, such as size, weight, battery life, and connectivity options.</p>
            `
        },
        {
            label: "Reviews [5]",
            content: `
                <ul>
                    <li>Excellent sound quality with a vintage touch.</li>
                    <li>Easy to carry, perfect for travel.</li>
                    <li>Durable and stylish design.</li>
                    <li>Worth the price for audiophiles.</li>
                    <li>Bass could be a bit deeper.</li>
                </ul>
            `
        }
    ];


    useEffect(() => {
        dispatch(fetchProductDetails(product_id))
    }, [dispatch, product_id]);

    // console.log(productDetails)

    return (
        <Layout>
            <div className="Add-to-cart-page">
                <div className="Images-gallary">
                    <ProductImages ImageList={productDetails?.product_details?.images} />

                </div>
                <div className="Product-details">
                    <ProductDetails Content={productDetails?.product_details} />
                </div>
            </div>
            <div className='Product-description'>
                <BasicTabs tab_label={tab_label} tab_value={tab_content} />
            </div>
            <div className="Product-related">
                <h1>Related Product</h1>
                <div className="Products-related-details">
                    <ProductCart data={productDetails?.other_products ?? []} />
                </div>
                <div className="Product-related-button">
                    <CustomButton lable={"Show more"} type="outlined" />
                </div>

            </div>
        </Layout>
    );
}

export default AddToCart;
