import { useEffect } from 'react';
import { Box, Card, CardContent, CardHeader, Divider, Stack } from '@mui/material';

import "./cart.scss";
import TableComponent from '../../component/Table';
import Layout from '../../component/Layout';
import CustomButton from '../../component/Button';
import { fetchOrderDetails, useAppDispatch } from "./controller";
import { useSelector } from 'react-redux';
import CouponBillingSection from '../../component/CouponBillingSection';
import Lottie from "lottie-react";
import emptyCartAnimation from "../../assets/empty_cart.json";
import { useNavigate } from 'react-router-dom';




function ShoppingCart() {
  const cartData = useSelector((state: any) => state.cartPage.data)
  const navigate = useNavigate();
  console.log("cartPage", cartData)
  const dispatch = useAppDispatch();
  const headers = ["Product Details", "Price"];
  useEffect(() => {
    dispatch(fetchOrderDetails())
  }, [dispatch])
  return (
    <Layout>
      {cartData?.order_details?.orders?.length > 0 ? <Box className="Shopping_Cart_Page">
        <Card elevation={0} className='cart_product_details'>
          <CardHeader title={
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <h1 className='text-primary_text'>Shopping Cart</h1>
              <h1 className='text-primary_text'>{cartData?.order_details?.orders?.length} Item</h1>

            </Box>
          } />
          {/* <Divider /> */}
          <CardContent>
            {cartData?.order_details && <TableComponent headers={headers} data={cartData?.order_details?.orders} />}
          </CardContent>
        </Card>

        <Card elevation={0} className='order_summary_details'>
          <CardHeader title={
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
              <h1 className='text-primary_text'>Order Summary</h1>
            </Box>
          } />
          <CouponBillingSection />
          {/* <Divider /> */}
          <CardContent>
            <Stack direction="column" spacing={2}>
              <Box className="flex items-center justify-between">
                <span className="text-primary_text font-medium text-[14px]">Cart Total <p className='text-primary_text font-medium text-[10px]'>{"(Excl. of all taxes)"}</p></span>
                <span className="text-primary_text font-medium text-[14px]">{cartData?.order_details.currency}{cartData?.order_details?.cart_total?.toFixed(2)}</span>
              </Box>
              <Box className="flex items-center justify-between">
                <span className="text-primary font-medium text-[14px]">Discount</span>
                <span className="text-primary font-medium text-[14px]">-{cartData?.order_details.currency}{cartData?.order_details?.discount?.toFixed(2)}</span>
              </Box>
              <Box className="flex items-center justify-between">
                <span className="text-primary_text font-medium text-[14px]">Shipping</span>
                <span className="text-primary_text font-medium text-[14px]">{cartData?.order_details.currency}{cartData?.order_details?.shipping_charges?.toFixed(2)}</span>
              </Box>
              <Box className="flex items-center justify-between">
                <span className="text-primary_text font-medium text-[14px]">GST</span>
                <span className="text-primary_text font-medium text-[14px]">{cartData?.order_details.currency}{cartData?.order_details?.gst?.toFixed(2)}</span>
              </Box>
              <Divider />
              <Box className="flex items-center justify-between">
                <span className="text-primary_text font-medium text-[14px]">Total Amount</span>
                <span className="text-primary_text font-medium text-[14px]">{cartData?.order_details.currency}{cartData?.order_details?.total_amount?.toFixed(2)}</span>
              </Box>
              <CustomButton lable={"Place Order"} type="contained" />

            </Stack>
          </CardContent>
        </Card>
      </Box> : <Box className="Shopping_Cart_Page empty_cart"><Card elevation={0} className='cart_product_details'>
        <Lottie className="h-[200px] w-full" animationData={emptyCartAnimation} loop={true} />
        <CardContent className='flex flex-col items-center gap-4'>
          <h1 className='text-primary_text text-center'>Your cart is empty</h1>
          <p className='text-primary_text text-center text-[14px]'>Please add something soon, cart has feelings too.</p>
          <div className="flex justify-center gap-4">
            <CustomButton lable={"Continue Shopping"} type="contained" clickFunction={()=> navigate("/")} />
          </div>
        </CardContent>
      </Card>
      </Box>}
    </Layout>
  );
};

export default ShoppingCart;