import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { getRequest, postRequest } from '../../services'
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../state_store/snackbarcontroller';




interface LoginPageState {
    data: any
}

const initialState: LoginPageState = {
    data: {
        LoginState: {
            status: false,
            data: [],
            message: "",
           
        },
        RegisterState: {
            status: false,
            data: [],
            message: "",
           
        },
        SendOtpState: {
            status: false,
            data: [],
            message: "",
           
        },
        ValidateOtpState: {
            status: false,
            data: [],
            message: "",
           
        },
    },
}

const LoginPageSlice = createSlice({
    name: 'LoginPage',
    initialState,
    reducers: {
        setLoginPage(state, action: PayloadAction<any>) {
            const { key, status, data, message, type } = action.payload;
            if (state.data[key]) {
                state.data[key] = {
                    ...state.data[key],
                    ...(status !== undefined && { status }),
                    ...(data !== undefined && { data }),
                    ...(message !== undefined && { message }),
                    ...(type !== undefined && { type }),
                };
            }
        }
    }
})

// Export actions
export const { setLoginPage } = LoginPageSlice.actions
// Export reducer
export default LoginPageSlice.reducer

// Thunk Function (Async Action Creator)
export const LoginAPI = (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    try {
        // Example: fetching data from an API
        const response: any = await postRequest('auth/log-in', data)
        if (response.status === 200 || response.status === 201) {
            const responseObject = {
                key: "LoginState",
                status: true,
                data: response.data,
                message: response.data.message,
                
            }
            dispatch(setLoginPage(responseObject))
            localStorage.setItem("user", JSON.stringify(response.data.user))
            localStorage.setItem("token", response.data.access_token)
            navigate("/")
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } else {
            const responseObject = {
                key: "LoginState",
                status: false,
                data: response?.data,
                message: response?.data?.message,
                
            }
            dispatch(setLoginPage(responseObject))
            console.log(response)
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }
    } catch (error) {
        console.error('Error fetching homepage data', error)

        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}
export const SendOTP = async (data: any) => async (dispatch: AppDispatch) => {
    try {

        const response: any = await postRequest('otp/send-otp', data)
        if (response.status === 200 || response.status === 201) {

            const responseObject = {
                key: "SendOtpState",
                status: true,
                data: response.data.data,
                message: response.data.data.message,
                
            }
            dispatch(setLoginPage(responseObject))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } else {
            const responseObject = {
                key: "SendOtpState",
                status: false,
                data: response?.data.data,
                message: response?.data?.data.message,
              
            }
            dispatch(setLoginPage(responseObject))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }
    } catch (error) {
        console.error('Error fetching homepage data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}
export const RegisterAPI = (data: any) => async (dispatch: AppDispatch) => {
    try {

        const response: any = await postRequest('users/sign-up', data)
        if  (response.status === 200 || response.status === 201) {

            const responseObject = {
                key: "RegisterState",
                status: true,
                data: response.data.data,
                message: response.data.message,
               
            }

            dispatch(setLoginPage(responseObject))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
            const sendOTPobj = {
                user_id: response.data?.data?._id,
                provider: "Phone",
                is_resend: false
            }
            const OTPresponse: any = await postRequest('otp/send-otp', sendOTPobj)
            if  (OTPresponse.status === 200 || OTPresponse.status === 201) {

                const responseObject = {
                    key: "SendOtpState",
                    status: true,
                    data: OTPresponse.data.data,
                    message: OTPresponse.data.message,
                   
                }
                dispatch(setLoginPage(responseObject))
                dispatch(setSnackbar({ open: true, message: OTPresponse.data.message, severity: "success" }));
            } else {
                const responseObject = {
                    key: "SendOtpState",
                    status: false,
                    data: response?.data.data,
                    message: response?.data.message,
                    
                }
                dispatch(setLoginPage(responseObject))
                dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
            }

        } else {
            const responseObject = {
                key: "RegisterState",
                status: false,
                data: response?.data.data,
                message: response?.data?.message,
               
            }
            dispatch(setLoginPage(responseObject))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }
    } catch (error) {
        console.error('Error fetching homepage data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}

export const ValidateOTP = (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    try {

        const response: any = await postRequest('otp/validate-otp', data)
        if (response.status === 200 || response.status === 201) {
            const responseObject = {
                key: "ValidateOtpState",
                status: true,
                data: response.data.data,
                message: response.data.message,
               
            }
            dispatch(setLoginPage(responseObject))
            localStorage.setItem("user", JSON.stringify(response.data.data.user))
            navigate("/")
            localStorage.setItem("token", response.data.data.access_token)
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } else {
            const responseObject = {
                key: "ValidateOtpState",
                status: false,
                data: response?.data.data,
                message: response?.data?.message,
               
            }
            dispatch(setLoginPage(responseObject))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }
    } catch (error) {
        console.error('Error fetching homepage data', error)
        // Optionally, handle the error by dispatching an error action or setting a specific error state
    }
}




export const useAppDispatch: () => AppDispatch = useDispatch;