import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { useDispatch } from 'react-redux';


interface SnackbarState {
    data: any
}

const initialState: SnackbarState = {
    data: {
        open: false,
        message: '',
        severity: ''
    }
}

const SnackbarSlice = createSlice({
    name: 'Snackbar',
    initialState,
    reducers: {
        setSnackbar(state, action: PayloadAction<any>) {
            state.data = action.payload  // Update state with action payload
        }
    }
})

// Export actions
export const { setSnackbar } = SnackbarSlice.actions
// Export reducer
export default SnackbarSlice.reducer

export const useAppDispatch: () => AppDispatch = useDispatch;