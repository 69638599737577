import { Box, Button, FormControl, MenuItem, Select } from '@mui/material';
// import Counter from '../../Counter';
// import CustomButton from '../../Button';
import './product_details.scss'
import CustomeSelect from '../../Select_Input';

import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, setOrderDetails, addOrderDetails } from '../../../pages/ProductDetails/controller';
import { useSelector } from 'react-redux';
import { useState } from 'react';




export default function ProductDetails({ Content }: any) {
    const navigate = useNavigate();
    const { product_id } = useParams();
    // console.log("product_id", product_id)
    const dispatch = useAppDispatch();
    const [err, setErr] = useState<any>([])
    const productSizeInfoSelect = useSelector((state: any) => state.productPage.data?.order_details)
    const product_size_info = Content ? Content?.product_size_info?.map((content: any) => ({
        _id: content?._id,
        Label: content?.size_field_name,
        List: content?.size_options,
        Type: content?.size_type,
        name: "product_size_info"
    })) : []

    const checkout_forms = Content ? Content?.checkout_forms?.map((content: any) => ({
        Label: content?.field_name,
        List: content?.list,
        Type: content?.form_type,
        _id: content?._id,
        name: "checkout_forms"
    })) : []
    const requiredFields = Content
        ? [
            ...(Content?.product_size_info?.map((content: any) => ({
                _id: content?._id,
                required: content?.size_field_mandatory,
                label: content?.size_field_name,
            })) || []),
            ...(Content?.checkout_forms?.map((content: any) => ({
                _id: content?._id,
                required: content?.form_required,
                label: content?.field_name,
            })) || []),
        ]
        : [];




    const handleFilterOptionsClick = async (label: string, product_id: string, id: string, type: string = "Single Select", name: string) => {
        const updatedSelection = [...productSizeInfoSelect];
        const existingIndex = updatedSelection.findIndex(
            (item: any) => item.Label === label
        );

        if (type === "Single Select" || type === "Color Select") {
            // For Single Select, only one value is allowed
            if (existingIndex !== -1) {
                // If label exists, update the selected value
                console.log("Remove the size", existingIndex, updatedSelection);
                updatedSelection[existingIndex] = {
                    ...updatedSelection[existingIndex],
                    Value: id, // Update the Value property
                    _id: product_id
                }; // Replace with the new selected id
            } else {
                // If label doesn't exist, add a new entry with the selected size
                updatedSelection.push({ Label: label, Value: id, _id: product_id, name: name });
            }
        } else if (type === "Multi Select") {
            if (existingIndex !== -1) {
                // Ensure the Value is an array
                if (!Array.isArray(updatedSelection[existingIndex].Value)) {
                    updatedSelection[existingIndex] = {
                        ...updatedSelection[existingIndex],
                        Value: [],
                        _id: product_id,
                        name: name // Update the Value property
                    };
                }

                // Toggle the selected value
                const selectedSizeIndex = updatedSelection[existingIndex].Value.indexOf(id);
                if (selectedSizeIndex !== -1) {
                    const selectedID = [...updatedSelection[existingIndex].Value];
                    selectedID.splice(selectedSizeIndex, 1); // Remove the size
                    updatedSelection[existingIndex] = {
                        ...updatedSelection[existingIndex],
                        Value: selectedID, // Update the Value property
                    };
                } else {
                    const selectedID = [...updatedSelection[existingIndex].Value, id];
                    updatedSelection[existingIndex] = {
                        ...updatedSelection[existingIndex],
                        Value: selectedID, // Update the Value property
                    };
                }
            } else {
                // Add a new entry with the selected size
                updatedSelection.push({ Label: label, Value: [id], _id: product_id, name: name });
            }
        }

        dispatch(setOrderDetails(updatedSelection));
    };
    const isActive = (label: string, id: string, type: string) => {
        if (type === "Multi Select") {
            return Array.isArray(productSizeInfoSelect) && productSizeInfoSelect.some((item: any) => item.Label === label && item.Value.includes(id));
        } else {
            return Array.isArray(productSizeInfoSelect) && productSizeInfoSelect.some((item: any) => item.Label === label && item.Value === id);
        }

    };

    const validateRequiredFields = (selectionArray: any[], requiredFieldsArray: any[]) => {
        // Find required fields with _id
        const errors = requiredFieldsArray
            .filter((field) => field.required) // Only process fields where required is true
            .filter((field) => {
                // Check if _id exists in the selection array and has a valid value
                const matchingItem = selectionArray.find((item) => item._id === field._id);
                return !matchingItem || !matchingItem.Value || (Array.isArray(matchingItem.Value) && matchingItem.Value.length === 0);
            })
            .map((field: any) => ({ _id: field._id, message: `Missing value for required field  ${field.label}` }));

        return errors;
    };

    const handleAddToCart = async () => {
        try {
            const user_details: any = localStorage.getItem('user')
            const userData = JSON.parse(user_details)
            if (!userData) {
                navigate('/login');
                return;
            }
            const errors = validateRequiredFields(productSizeInfoSelect, requiredFields);
            if (errors.length) {
                setErr(errors)
                return;
            }
            // console.log(productSizeInfoSelect)
            const public_sizes = Array.isArray(productSizeInfoSelect)
                ? productSizeInfoSelect
                    .map((productSizeInfo: any) => {
                        if (productSizeInfo?.name === "product_size_info") {
                            if (Array.isArray(productSizeInfo.Value)) {
                                const value = productSizeInfo.Value.map((data: any) => ({ _id: data }));
                                console.log(value)
                                return {
                                    _id: productSizeInfo._id,
                                    user_selection: value,
                                };
                            }
                            return {
                                _id: productSizeInfo._id,
                                user_selection: { _id: productSizeInfo.Value },
                            };
                        }
                        return undefined; // Ensures consistent return type
                    })
                    .filter(Boolean) // Removes undefined values
                : [];


            const checkout_forms = Array.isArray(productSizeInfoSelect)
                ? productSizeInfoSelect
                    .map((productSizeInfo: any) => {
                        if (productSizeInfo?.name === "checkout_forms") {
                            return {
                                _id: productSizeInfo._id,
                                user_selection: { _id: productSizeInfo.Value },
                            };
                        }
                        return undefined; // Ensures consistent return type
                    })
                    .filter(Boolean) // Removes undefined values
                : [];




            const dataobj = {
                product_id: product_id,
                public_sizes: public_sizes,
                checkout_forms: checkout_forms,
                coupon_code: null
            }
            await dispatch(addOrderDetails(dataobj));
            navigate('/cart');

        } catch (error) {
            console.error(error)
        }
    }


    return (
        <div className='w-full flex items-start justify-between p-[40px]'>
            <div className='ProductCart-text'>
                <h1 className='text-primary_text'>{Content?.name ?? ""}</h1>
                {/* Start for the customer review */}
                <div className='flex sm:flex-row flex-col justify-start gap-[20px] sm:items-center items-start sm:w-[30%] w-full'>
                    <h2 className='text-primary_text'>{Content?.currency} {Content?.base_price ?? ""}</h2>
                    <h2 className='text-primary_text line-through'>{Content?.currency} {Content?.max_price ?? ""}</h2>
                </div>
                {/* <h2 className='text-primary_text'>{Content?.currency} {Content?.base_price ?? ""}</h2> */}
                <h3 className='text-secondary_text'>Setting the bar as one of the loudest speakers in its class, the Kilburn is a compact, stout-hearted hero with a well-balanced audio which boasts a clear midrange and extended highs for a sound. </h3>
                {
                    Array.isArray(product_size_info) &&
                    product_size_info.map((options: any, index: number) => {
                        const isError = err.find((item: any) => item._id === options._id);

                        if (options?.Type === "Single Select") {
                            return (
                                <div className="size-chart" key={`size-chart-${index}`}>
                                    <p className="text-secondary_text">{options?.Label}</p>
                                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                        {Array.isArray(options?.List) &&
                                            options.List.map((size: any) => (
                                                <Button
                                                    key={`option-${options?.Label}-${size?._id}`}
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        minWidth: '50px',
                                                        padding: '6px 12px',
                                                        textTransform: 'none',
                                                    }}
                                                    className={isActive(options?.Label, size?._id, options?.Type) ? "CheckBox_Size-active" : 'CheckBox_Size'}
                                                    onClick={() => handleFilterOptionsClick(options?.Label, options?._id, size?._id, options?.Type, options?.name)}
                                                >
                                                    {size?.detail}
                                                </Button>
                                            ))}
                                    </Box>
                                    <p className="error-message text-red-500 mt-2 text-[13px]">{isError?.message}</p>
                                </div>
                            );
                        }
                        if (options?.Type === "Color Select") {
                            return (
                                <div className="size-chart" key={`size-chart-${index}`}>
                                    <p className="text-secondary_text">{options?.Label}</p>
                                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                        {Array.isArray(options?.List) &&
                                            options.List.map((size: any) => (
                                                <div
                                                    className={isActive(options?.Label, size?._id, options?.Type) ? "color_box-active" : 'color_box'}
                                                    style={{ backgroundColor: size?.detail }}
                                                    onClick={() => handleFilterOptionsClick(options?.Label, options?._id, size?._id, options?.Type, options?.name)}
                                                ></div>
                                            ))}
                                    </Box>
                                    <p className="error-message text-red-500 mt-2 text-[13px]">{isError?.message}</p>
                                </div>
                            );
                        }
                        if (options?.Type === "Multi Select") {
                            return (
                                <div className="size-chart" key={`size-chart-${index}`}>
                                    <p className="text-secondary_text">{options?.Label}</p>
                                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                        {Array.isArray(options?.List) &&
                                            options.List.map((size: any) => (
                                                <Button
                                                    key={`option-${options?.Label}-${size?._id}`}
                                                    variant="outlined"
                                                    sx={{
                                                        borderRadius: '8px',
                                                        minWidth: '50px',
                                                        padding: '6px 12px',
                                                        textTransform: 'none',
                                                    }}
                                                    className={isActive(options?.Label, size?._id, options?.Type) ? "CheckBox_Size-active" : 'CheckBox_Size'}
                                                    onClick={() => handleFilterOptionsClick(options?.Label, options?._id, size?._id, options?.Type, options?.name)}
                                                >
                                                    {size?.detail}
                                                </Button>
                                            ))}
                                    </Box>
                                    <p className="error-message text-red-500 mt-2 text-[13px]">{isError?.message}</p>
                                </div>
                            );
                        }
                        if (options?.Type === "Text Field") {
                            return (
                                <div className="size-chart" key={`size-chart-${index}`}>
                                    <p className="text-secondary_text">{options?.Label}</p>
                                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                        {Array.isArray(options?.List) &&
                                            options.List.map((size: any) => (
                                                <div
                                                    className="rounded-[50%] h-[35px] w-[35px] cursor-pointer"
                                                    style={{ backgroundColor: size?.detail }}
                                                    onClick={() => handleFilterOptionsClick(options?.Label, options?._id, size?._id, options?.Type, options?.name)}
                                                ></div>
                                            ))}
                                    </Box>
                                    <p className="error-message text-red-500 mt-2 text-[13px]">{isError?.message}</p>
                                </div>
                            );
                        }
                        return null; // Ensure map always returns a value
                    })
                }
                {
                    Array.isArray(checkout_forms) &&
                    checkout_forms.map((options: any, index: number) => {
                        const isError = err.find((item: any) => item._id === options._id);
                        if (options?.Type === "Single Select") {
                            const selected = productSizeInfoSelect.find(
                                (select: any) => select.Label === options?.Label
                            )?.Value || "";
                            return (
                                <div className="size-chart" key={`size-chart-${index}`}>
                                    <p className="text-secondary_text">{options?.Label}</p>
                                    <Box display="flex" flexWrap="wrap" gap={1} mt={2}>
                                        <Box sx={{ minWidth: 120, width: '100%', }}>
                                            <FormControl fullWidth size="small" className="custom-select">
                                                {/* {!label && <InputLabel id="demo-simple-select-label">"Sort"</InputLabel>} */}
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={selected}
                                                    onChange={(e: any) => handleFilterOptionsClick(options?.Label, options?._id, e.target.value, options?.Type, options?.name)}
                                                    displayEmpty
                                                >
                                                    <MenuItem value="" disabled>
                                                        Select an option
                                                    </MenuItem>
                                                    {options?.List?.map((item: any) => (
                                                        <MenuItem key={item._id} value={item._id}>
                                                            {item.detail}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Box >
                                    </Box>
                                    <p className="error-message text-red-500 mt-2 text-[13px]">{isError?.message}</p>
                                </div>
                            );
                        }
                        return null; // Ensure map always returns a value
                    })
                }

                <div className='Add-cart-page-button-group'>
                    <div className='Add-button'>
                        <Button variant={'outlined'} onClick={handleAddToCart} className='button'>{"Add to Cart"}</Button>
                    </div>
                    <div className='Add-button'>
                        <Button variant={'outlined'} className='button'>{"Buy Now"}</Button>
                    </div>
                </div>
                <hr className="h-[1px] w-full  border-0 my-[10px]" />
                <div className='Product-details'>
                    {/* make it in the loop */}
                    <ul>
                        <li className='text-secondary_text mb-[10px]'>Brand  : Asgaard</li>
                        <li className='text-secondary_text my-[10px]'>Model  : Kilburn</li>
                        <li className='text-secondary_text my-[10px]'>Dimensions  : 180 cm x 180 cm x 120 cm</li>
                        <li className='text-secondary_text my-[10px]'>Color  : Black</li>
                        <li className='text-secondary_text my-[10px]'>Weight  : 1.5 kg</li>
                        <li className='text-secondary_text mt-[10px]'>Material  : Wood</li>
                    </ul>

                </div>





            </div>

        </div>
    );
}