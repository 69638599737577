import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppDispatch } from '../../state_store/index' // Importing AppDispatch type for Thunks
import { getRequest, postRequest } from '../../services'
import { useDispatch } from 'react-redux';
import { setSnackbar } from '../../state_store/snackbarcontroller';




interface ProductPageState {
    data: {
        page_details: any,
        order_details: any,
    }
}

const initialState: ProductPageState = {
    data: {
        page_details: {},
        order_details: [],

    },
}

const ProductPageSlice = createSlice({
    name: 'ProductPage',
    initialState,
    reducers: {
        setPageDetails(state, action: PayloadAction<any>) {
            state.data.page_details = action.payload; // Merge with existing page details
        },
        setOrderDetails(state, action: PayloadAction<any>) {
            state.data.order_details = action.payload; // Merge with existing order details
        },
    }
})

// Export actions
export const { setPageDetails, setOrderDetails } = ProductPageSlice.actions
// Export reducer
export default ProductPageSlice.reducer

// Thunk Function (Async Action Creator)
export const fetchProductDetails = (id: any) => async (dispatch: AppDispatch) => {
    try {
        // Example: fetching data from an API
        const response: any = await getRequest(`products/product-detail?product_id=${id}`)
        if (response.status === 200 || response.status === 201) {
            dispatch(setPageDetails(response.data.data))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } else {
            dispatch(setPageDetails({}))
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }
    } catch (error: any) {
        dispatch(setPageDetails({}))
        dispatch(setSnackbar({ open: true, message: error?.response.data.message, severity: "error" }));
        console.error('Error fetching homepage data', error)
    }
}

export const addOrderDetails = (order: any) => async (dispatch: AppDispatch) => {
    try {
        const response: any = await postRequest(`orders/cart`, order);

        if (response.status === 200 || response.status === 201) {
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "success" }));
        } else {
            dispatch(setSnackbar({ open: true, message: response.data.message, severity: "error" }));
        }

        return response; // ✅ Return the response so that `await dispatch(...)` works
    } catch (error: any) {
        dispatch(setSnackbar({ open: true, message: error?.response?.data?.message || "Error", severity: "error" }));
        console.error("Error adding order details", error);
        
        throw error; // ✅ Ensure errors are thrown so the caller can handle them
    }
};





export const useAppDispatch: () => AppDispatch = useDispatch;