import React, { useEffect, useState } from 'react';
import "./onboarding.scss"
import CustomButton from '../../component/Button';
import TextFieldCustom from '../../component/TextField';
import Layout from '../../component/Layout';
import { RegisterAPI, LoginAPI, useAppDispatch, ValidateOTP } from "./controller";
import validator from 'validator';
import { validateRegisterForm } from '../../utlis';
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

const LoginFormObject = {
    email: {
        label: "Email",
        type: "email",
        placeholder: "Enter your email",
        error: false,
        value: "",
        required: true,
        message: ""
    },
    password: {
        label: "Password",
        type: "text",
        placeholder: "Enter your password",
        error: false,
        value: "",
        required: true,
        message: "",
    },

}

const RegisterFormObject = {

    name: [{
        label: "First Name",
        type: "text",
        placeholder: "Enter your first name",
        error: false,
        value: "",
        required: true,
        message: "",
    },
    {
        label: "Last Name",
        type: "text",
        placeholder: "Enter your last name",
        error: false,
        value: "",
        required: true,
        message: "",
    },],

    email: {
        label: "Email",
        type: "email",
        placeholder: "Enter your email",
        error: false,
        value: "",
        required: true,
        message: "",
    },
    password: {
        label: "Password",
        type: "text",
        placeholder: "Enter your password",
        error: false,
        value: "",
        required: true,
        message: "",
    },
    confirm_password: {
        label: "Confirm Password",
        type: "text",
        placeholder: "Confirm your password",
        error: false,
        value: "",
        required: true,
        message: "",
    },
    date_of_birth: {
        label: "Date of Birth",
        type: "date",
        placeholder: "Enter your date of birth",
        error: false,
        value: "",
        required: true,
        message: "",
    },
    phone: [
        {
            label: "Country",
            type: "text",
            placeholder: "+91",
            error: false,
            value: "+91",
            required: true,
            message: "",
        },
        {
            label: "Phone Number",
            type: "number",
            placeholder: "Enter your phone number",
            error: false,
            value: "",
            required: true,
            message: "",
        },
    ],
    gender: {
        label: "Gender",
        type: "radio",
        options: [{ name: "Male", value: "Male" }, { name: "Female", value: "Female" }, { name: "Other", value: "Other" }],
        error: false,
        value: "Male",
        required: true,
        message: "",
    }
}
const Login = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const LoginPageData = useSelector((state: any) => state.loginPage.data)
    const [otp, setOtp] = useState<any>('')
    const [LoginForm, setLoginForm] = useState<any>(LoginFormObject);
    const [RegisterForm, setRegisterForm] = useState<any>(RegisterFormObject);
    const [Selected, setSelected] = useState<any>("LOGIN")



    const handleChange = (e: any, key: string, label: string = "") => {

        if (Selected === "LOGIN") {
            setLoginForm({
                ...LoginForm,
                [key]: {
                    ...LoginForm[key],
                    value: e,
                    error: false,
                    message: "",
                },
            });
            if (key === "email") {
                if (!validator.isEmail(e)) {

                    setLoginForm((Login: any) => {
                        return {
                            ...Login,
                            email: { ...Login.email, value: e, error: true, message: "Invalid email address" },
                        };
                    });
                    return;
                } else {
                    setLoginForm((Login: any) => {
                        return {
                            ...Login,
                            email: { ...Login.email, value: e, error: false, message: "" },
                        };
                    });
                    return;
                }
            }
        } else {

            if (label) {

                if (label === "First Name" || label === "Last Name") {
                    setRegisterForm((prevState: any) => {
                        return {
                            ...prevState,
                            name: prevState.name.map((item: any) =>

                                item.label === label
                                    ? {
                                        ...item, value: e, error: false,
                                        message: "",
                                    }
                                    : item

                            ),
                        };
                    });
                    return;
                }

                if (label === "Phone Number") {
                    if (e.length > 10) {
                        setRegisterForm((prevState: any) => {
                            return {
                                ...prevState,
                                phone: prevState.phone.map((item: any) =>
                                    item.label === label
                                        ? {
                                            ...item, value: e, error: true,
                                            message: `Invalid ${label}`,
                                        }
                                        : item

                                ),
                            };
                        });
                        return;
                    }
                    setRegisterForm((prevState: any) => {
                        return {
                            ...prevState,
                            phone: prevState.phone.map((item: any) =>
                                item.label === label
                                    ? {
                                        ...item, value: e, error: false,
                                        message: "",
                                    }
                                    : item

                            ),
                        };
                    });
                    return;
                }


            } else {

                setRegisterForm({
                    ...RegisterForm,
                    [key]: {
                        ...RegisterForm[key],
                        value: e,
                        error: false,
                        message: "",
                    },
                });
                if (key === "email") {
                    if (!validator.isEmail(e)) {

                        setRegisterForm((RegisterForm: any) => {
                            return {
                                ...RegisterForm,
                                email: { ...RegisterForm.email, value: e, error: true, message: "Invalid email address" },
                            };
                        });
                        return;
                    } else {

                        setRegisterForm((RegisterForm: any) => {
                            return {
                                ...RegisterForm,
                                email: { ...RegisterForm.email, value: e, error: false, message: "" },
                            };
                        });
                        return;
                    }
                }
            }

        }



    };

    const handleSelect = (value: String) => {
        setSelected(value)
    }
    const handleOTPChange = (newValue: any) => {
        setOtp(newValue)
    }


    const handleClick = () => {

        if (Selected === "REGISTER") {
            const validatedForm = validateRegisterForm(RegisterForm, setRegisterForm);

            if (!validatedForm) {
                return;
            }
            if (RegisterForm['password'].value !== RegisterForm["confirm_password"].value) {
                setRegisterForm((Register: any) => {
                    return {
                        ...Register,
                        confirm_password: { ...Register.confirm_password, error: true, message: "Password not matched!" },
                    }
                })
                return;
            }
            if (RegisterForm['confirm_password'].value !== RegisterForm["password"].value) {

                setRegisterForm((Register: any) => {
                    return {
                        ...Register,
                        password: { ...Register.password, error: true, message: "Password not matched!" },
                    }
                })
                return;
            }
            const date = new Date(RegisterForm["date_of_birth"].value); // Convert to Date object
            const timestampInMilliseconds = date.getTime();
            const data = {
                first_name: RegisterForm["name"].find((value: any) => value.label === "First Name")?.value || "",
                last_name: RegisterForm["name"].find((value: any) => value.label === "Last Name")?.value || "",
                email: RegisterForm["email"].value,
                password: RegisterForm["password"].value,
                confirm_password: RegisterForm["confirm_password"].value,
                date_of_birth: timestampInMilliseconds,
                country_code: RegisterForm["phone"].find((value: any) => value.label === "Country")?.value || "",
                phone_no: RegisterForm["phone"].find((value: any) => value.label === "Phone Number")?.value || "",
                gender: RegisterForm["gender"].value,

            }
            dispatch(RegisterAPI(data));


        } else {

            const validatedForm = validateRegisterForm(LoginForm, setLoginForm);
            if (!validatedForm) {
                return;
            }
            const data = {
                email: LoginForm["email"].value,
                password: LoginForm["password"].value,
            }
            dispatch(LoginAPI(data, navigate));

        }

    }

    const handleOTPClick = () => {
        const obj_data = {
            user_id: LoginPageData?.SendOtpState?.data?.user_id,
            provider: "Phone",
            otp_code: Number(otp)
        }

        dispatch(ValidateOTP(obj_data, navigate))
    }

    useEffect(() => {
        if (LoginPageData?.RegisterState?.status) {
            setSelected("OTP")
        }
    }, [LoginPageData?.RegisterState?.status])


    return (
        <Layout>
            <div className="Onboarding-Page ">
                <div className="Onboarding-component-form ">
                    <div className="Onboarding-select-box ">
                        <button
                            className={Selected === "LOGIN" ? "Onboarding-login-button active" : "Onboarding-login-button notactive"}
                            onClick={() => handleSelect("LOGIN")}
                        >
                            LOGIN
                        </button>
                        <button
                            className={(Selected === "REGISTER" || Selected === "OTP") ? "Onboarding-register-button active" : "Onboarding-register-button notactive"}
                            onClick={() => handleSelect("REGISTER")}
                        >
                            REGISTER
                        </button>
                    </div>

                    {(Selected === "LOGIN" || Selected === "REGISTER") && <div className="Onboarding-auth-box ">
                        <button
                            className="Onboarding-facebook-button "
                        >
                            <span>Facebook</span>
                        </button>
                        <button
                            className="Onboarding-google-button "
                        >
                            <span>Google</span>
                        </button>
                        <div className="text-center text-gray-400">- OR -</div>
                        {Object.keys(Selected === "LOGIN" ? LoginForm : RegisterForm).map((key: string, ind: number) => {
                            const formData = Selected === "LOGIN" ? LoginForm[key] : RegisterForm[key];

                            return (
                                <div key={ind} style={{ width: "100%" }}>
                                    {formData.type === "radio" ? (
                                        // Render radio input
                                        <div>
                                            <h3 className="form_label">{formData.label}</h3>
                                            <div className='flex justify-start gap-[10px]'>
                                                {formData.options?.map((option: any, index: number) => (
                                                    <div key={index} className='flex items-center mb-[8px]'>
                                                        <input
                                                            type="radio"
                                                            name={key} // Ensure all radio buttons in a group have the same name
                                                            value={option.value}
                                                            checked={formData.value === option.value}
                                                            onChange={(e) => handleChange(option.value, key)}
                                                            required={formData.required}
                                                        />
                                                        <span style={{ marginLeft: "8px" }}>{option.name}</span>

                                                    </div>
                                                ))}
                                            </div>


                                        </div>

                                    ) : (
                                        Array.isArray(formData) ?
                                            <div className='flex justify-between items-center'>
                                                {formData.map((x: any) => {
                                                    return (<div className={`${x.label === "Country" ? "w-[20%]" : x.label === "Phone Number" ? "w-[75%]" : "w-[45%]"}`}>
                                                        <TextFieldCustom
                                                            placeholder={x.placeholder}
                                                            type={x.type}
                                                            message={x.message}
                                                            value={x.value}
                                                            required={x.required}
                                                            error={x.error}
                                                            onChangeFunction={(e: any) => handleChange(e.target.value, key, x.label)}
                                                            label={x.label}
                                                        />
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                            :
                                            // Render standard TextFieldCustom input
                                            <TextFieldCustom
                                                placeholder={formData.placeholder}
                                                type={formData.type}
                                                message={formData.message}
                                                value={formData.value}
                                                required={formData.required}
                                                error={formData.error}
                                                onChangeFunction={(e: any) => handleChange(e.target.value, key)}
                                                label={formData.label}
                                            />
                                    )}
                                </div>
                            );
                        })}
                        <br />
                        <CustomButton lable={Selected === "LOGIN" ? "PROCEED" : "REGISTER"} type="contained" clickFunction={() => handleClick()} />

                    </div>}


                    {Selected === "OTP" && <div className='OTP-form'>
                        <h4 className="text-center text-[15px] text-gray-400 mb-[30px]">We have sent you an OTP via SMS</h4>
                        <div className='w-full'>
                            <TextFieldCustom
                                placeholder={""}
                                type={"text"}
                                message={""}
                                value={`${RegisterForm["phone"].find((value: any) => value.label === "Country")?.value}- ${RegisterForm["phone"].find((value: any) => value.label === "Phone Number")?.value}`}
                                required={false}
                                error={false}
                                onChangeFunction={(e: any) => console.log(e)}
                                label={"Contact Number"}
                            />
                        </div>

                        <MuiOtpInput length={4} value={otp} onChange={handleOTPChange} />
                        <div className='w-full'>
                            <h4 className="text-center text-[15px] text-gray-400 text-end underline">Resend OTP</h4>
                        </div>
                        <div className='w-full'>
                            <CustomButton lable={Selected === "LOGIN" ? "PROCEED" : "REGISTER"} type="contained" clickFunction={() => handleOTPClick()} />
                        </div>


                    </div>}

                    <div className="Onboarding-new-user ">
                        {Selected === "LOGIN" ? "New" : "Old"} User?{' '}
                        <p
                            className="Onboarding-create-account"
                            onClick={() => handleSelect(Selected === "LOGIN" ? "REGISTER" : "LOGIN")}
                        >
                           {Selected === "LOGIN" ? "Create Account" : "Login"}
                        </p>
                    </div>
                    
                </div>
            </div>
        </Layout>
    );
};

export default Login;




